<template>
	<div style="width: 100%;" v-if="$store.state.user.id > 0">
		<NavBar></NavBar>
		<div class="internal-container">

			<div class="dashboard-container">

				<div class="account-tile-hold" style="margin-top: 0;">
					<div style="padding: 20px;">
						<p class="heading">Reports</p>
						<div style="width: 1000px; justify-content: space-between; display: flex;">
							<b-select v-model="reporttype" style="width: 300px;">
								<option value="balance-sheet">Balance Sheet</option>
								<option value="income-expenses">Income & Expenses (Net Savings)</option>
								<option value="realised-capital-gains">Realised Capital Gains</option>
								<option value="unrealised-capital-gains">Unrealised Capital Gains</option>
							</b-select>

							<b-input-group v-if="reporttype === 'income-expenses'" class="datechoose" style="width: 300px;">
								<b-form-input
									v-model="startdate"
									type="text"
									placeholder="YYYY-MM-DD"
									autocomplete="off"
								></b-form-input>
								<b-input-group-append>
									<b-form-datepicker
										v-model="startdate"
										button-only
										right
										locale="en-US"
									></b-form-datepicker>
								</b-input-group-append>
							</b-input-group>

							<b-input-group class="datechoose" style="width: 300px;">
								<b-form-input
										v-model="enddate"
										type="text"
										placeholder="YYYY-MM-DD"
										autocomplete="off"
								></b-form-input>
								<b-input-group-append>
									<b-form-datepicker
											v-model="enddate"
											button-only
											right
											locale="en-US"
									></b-form-datepicker>
								</b-input-group-append>
							</b-input-group>

							<span class="kbtn kbtn-inline" style="margin-left: 10px;" @click="doSummaryLookups">Fetch</span>

							<span class="kbtn kbtn-inline" style="margin-left: 10px;" @click="exportToExcel('exportTable')">Export</span>
						</div>

						<div class="report-holder">
							<div v-if="reporttype === 'income-expenses'" style="margin-bottom: 20px;">
								<div style="display: flex; gap: 10px;">
									<b-form-checkbox v-model="incexpAccChoices.includeSuper"><span style="margin-left: 5px;">Include Super</span></b-form-checkbox>
									<b-form-checkbox v-model="incexpAccChoices.includeWCNPAT"><span style="margin-left: 5px;">Include WC NPAT</span></b-form-checkbox>
									<b-form-checkbox v-model="incexpAccChoices.includeWCInvestments"><span style="margin-left: 5px;">Include WC Investments</span></b-form-checkbox>
									<b-form-checkbox v-model="incexpAccChoices.includeWIH"><span style="margin-left: 5px;">Include WIH/Bucket</span></b-form-checkbox>
								</div>
							</div>

							<div v-if="reporttype === 'income-expenses' && incexpdata.balances">
								<div>
									<table class="report-table" id="exportTable">
										<tr>
											<td @click="getTransactions(null)" class="bold">View All Transactions</td>
											<td class="bold rt"></td>
										</tr>
										<tr>
											<td class="bold">Balance At Start</td>
											<td class="bold rt">{{numdisplay(incexpdata.balances.start)}}</td>
										</tr>
										<tr>
											<td class="bold">Balance At End</td>
											<td class="bold rt">{{numdisplay(incexpdata.balances.end)}}</td>
										</tr>
										<tr>
											<td class="bold">Net Change</td>
											<td class="bold rt">{{numdisplay(incexpdata.balances.diff)}}</td>
										</tr>
										<tr>
											<td class="bold">Category Totals</td>
											<td class="bold rt"></td>
										</tr>
										<tr v-for="cat in incexpdata.categoryTotals" :key="cat.subcatId">
											<td @click="getTransactions(cat.subcatId)" class="catclicker">{{cat.subCatName}}</td>
											<td class="rt">{{numdisplay(cat.val)}}</td>
										</tr>
										<tr>
											<td class="bold">Summary Totals</td>
											<td class="bold rt"></td>
										</tr>
										<tr>
											<td class="bold">Total Expenses</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Total Expenses'])}}</td>
										</tr>
										<tr>
											<td class="bold">Total Expenses (Capex)</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Total Capex'])}}</td>
										</tr>
										<tr>
											<td class="bold">Total Expenses (Opex)</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Total Opex'])}}</td>
										</tr>
										<tr>
											<td class="bold">Dividend Income (non Walk/Tracka)</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Dividend Income'])}}</td>
										</tr>
										<tr>
											<td class="bold">Interest Income</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Interest Income'])}}</td>
										</tr>
										<tr>
											<td class="bold">Realised Capital Gains</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Realised Gains'])}}</td>
										</tr>
										<tr>
											<td class="bold">Unrealised Capital Gains (period end vs start)</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Unrealised Gains'])}}</td>
										</tr>
										<tr>
											<td class="bold">Walk Estimated Operating Gains (NPAT)</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Walk NPAT'])}}</td>
										</tr>
										<tr>
											<td class="bold">Non Walk/CG Net Savings</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Non Walk Net Savings'])}}</td>
										</tr>
										<tr>
											<td class="bold">Non CG Net Savings</td>
											<td class="rt bold">{{numdisplay(incexpdata.typeTotals['Non CG Net Savings'])}}</td>
										</tr>
									</table>
								</div>
							</div>


							<div v-if="totals.super && reporttype === 'balance-sheet'">
								<div>
									<table class="report-table" id="exportTable">
										<tr v-for="acc in nonsuperaccts" :key="acc.id">
											<td class="report-td-name" :title="'ID '+acc.id">{{acc.name}}</td>
											<td class="rt">{{numdisplay(acc.lastbal)}}</td>
										</tr>
										<tr>
											<td class="bold">Total Personal Cash</td>
											<td class="bold rt">{{numdisplay(totals.nonsupercash)}}</td>
										</tr>
										<tr>
											<td class="bold">Total Investible</td>
											<td class="bold rt">{{numdisplay(totals.nonsuperinvestible)}}</td>
										</tr>
										<tr>
											<td class="bold">Invested (excl WC)</td>
											<td class="bold rt">{{numdisplay(totals.nonsuperinvested)}}</td>
										</tr>
										<tr>
											<td class="bold">Total excl WC</td>
											<td class="bold rt">{{numdisplay(totals.nonsuperexclwc)}}</td>
										</tr>
										<tr>
											<td class="bold">Grand Total Non-Super</td>
											<td class="bold rt">{{numdisplay(totals.nonsupergrandtotal)}}</td>
										</tr>
										<tr><td>-----</td></tr>
										<tr v-for="acc in superaccts" :key="acc.id">
											<td>{{acc.name}} {{acc.id}}</td>
											<td class="rt">{{numdisplay(acc.lastbal)}}</td>
										</tr>
										<tr>
											<td class="bold">Total</td>
											<td class="bold rt">{{numdisplay(totals.super)}}</td>
										</tr>
										<tr><td>-----</td></tr>
										<tr>
											<td class="bold">Grand Total excl FT Loan</td>
											<td class="bold rt">{{numdisplay(totals.grandtotalexclFTloan)}}</td>
										</tr>
										<tr>
											<td class="bold">Grand Total excl House & FT Loan</td>
											<td class="bold rt">{{numdisplay(totals.grandtotalexclhouse)}}</td>
										</tr>
										<tr>
											<td class="bold">Grand Total</td>
											<td class="bold rt">{{numdisplay(totals.grandtotal)}}</td>
										</tr>
									</table>
								</div>
							</div>

							<div v-if="rcglotslist.length > 0 && (reporttype === 'realised-capital-gains' || reporttype === 'unrealised-capital-gains')">
								<div v-html="rcglotslist"></div>
							</div>

						</div>

					</div>
				</div>

			</div>
		</div>

		<div class="overlay" v-if="txTableViewShown">
			<div style="width: 1200px; margin: 50px auto 0; height: 800px; overflow: auto;">
				<RegisterTable :intransactions="transactions" :inmodal="true" :showall="true"
					:showrunningbal="false" :showaccountname="true" :showcategory="false"
					v-on:closemodal="closeTxListView"></RegisterTable>
			</div>
		</div>

	</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import axios from "axios";
import TableToExcel from "@linways/table-to-excel";
import RegisterTable from "@/components/RegisterTable.vue";
export default {
	name: "Reports",
	components: {
		RegisterTable,
		NavBar
	},
	props: {},
	data: function () {
		return {
			lookupdone: false,
			reporttype: 'balance-sheet',
			enddate: "2021-05-31",
			startdate: "2021-05-31",
			superaccts: [],
			nonsuperaccts: [],
			totals: {},

			rcglotslist: '',
			incexpdata: {},
			incexpAccChoices: {
				includeSuper: true,
				includeWCNPAT: true,
				includeWCInvestments: true,
				includeWIH: true,
			},

			txTableViewShown: false,
			transactions: [],
			accounts: [],
		}
	},
	computed: {

	},
	methods: {
		closeTxListView() {
			this.txTableViewShown = false
			this.transactions = []
		},
		getTransactions(subcatid) {
			let self = this
			let subs = []
			if (subcatid) {
				subs = [subcatid]
			}
			let form = {
				startdate: this.startdate,
				enddate: this.enddate,
				subcatids: subs,
				incexpAccChoices: this.incexpAccChoices,
			};
			axios.post(
				"/post/get-transactions.php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				var ret = response.data //response.data is returned info
				if (ret.transactions) {
					self.transactions = ret.transactions
					self.txTableViewShown = true
				}
			}).catch(function (error) {
				console.log(error)
			});
		},
		exportToExcel(tblid, filename = 'export') {
			TableToExcel.convert(document.getElementById(tblid), {
				name: filename+".xlsx",
				sheet: {
					name: 'Sheet 1'
				}
			})
		},
		doSummaryLookups() {
			let self = this;
			let form = {
				type: 'report',
				startdate: this.startdate,
				enddate: this.enddate,
				incexpAccChoices: this.incexpAccChoices,
			};
			axios.post(
				"/post/reports/"+this.reporttype+".php",
				JSON.stringify({
					data: form,
				})
			).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.super) {
					self.superaccts = ret.super;
				}
				if (ret.nonsuper) {
					self.nonsuperaccts = ret.nonsuper;
				}
				if (ret.totals) {
					self.totals = ret.totals;
				}
				if (ret.lotcglist) {
					self.rcglotslist = ret.lotcglist;
				}
				if(ret.incexpdata) {
					self.incexpdata = ret.incexpdata;
				}
				self.lookupdone = true;
			}).catch(function (error) {
				console.log(error);
			});
		},

	},
	watch: {

	},
	mounted() {
		document.title = "Fintrac - Reports";
		this.startdate = this.dateobToYmd(new Date());
		this.enddate = this.dateobToYmd(new Date());
	}
}
</script>

<style scoped>
.report-holder {
	background-color: #FFF;
	padding: 30px;
	margin: 30px 0;
}
.report-table tr {
	border-bottom: 1px solid #EEE;
}
.report-table td {
	padding: 4px 5px 3px;
}
.report-table .report-td-name {
	width: 300px;
}
.catclicker {
	cursor: pointer;
}
</style>