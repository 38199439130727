import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import App from './App.vue'

import Login from "@/components/views/Login";
import Logout from "@/components/views/Logout";
import Dashboard from "@/components/views/Dashboard";
import RulesView from "@/components/views/RulesView";
import AccountView from "@/components/views/AccountView";
import Investing from "@/components/views/Investing";
import Superannuation from "@/components/views/Superannuation";
import Reports from "@/components/views/Reports";
import NetWorth from "@/components/views/NetWorth";
import Allocation from "@/components/views/Allocation";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './includes/style.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

//routing
const routes = [
	{path: '/login', component: Login},
	{path: '/logout', component: Logout},
	{path: '/dashboard', component: Dashboard},
	{path: '/rules', component: RulesView},
	{path: '/investing', component: Investing},
	{path: '/superannuation', component: Superannuation},
	{path: '/reports', component: Reports},
	{path: '/net-worth-chart', component: NetWorth},
	{path: '/asset-allocation-chart', component: Allocation},
	{path: '/account/:accid', component: AccountView},

];

const router = new VueRouter({
	history: true,
	hashbang: false,
	routes: routes,
	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		}
		return {x: 0, y: 0};
	},
});

const store = new Vuex.Store({
	state: {
		sesscheckcomplete: false,
		kalert: {
			shown: false,
			text: "",
			type: 'danger',
		},
		user: {
			id: 0,
		},
		activeSection: '',
		accounts: [],
		activeAccountId: 0,
		pocketsmithsyncing: false,
		rulesrunning: false,
		wsjstocksyncing: false,
		wsjcheckcount: 0,
		payees: [],
		subcats: [],
		securities: [],
		recentReconciledTransactions: [],
	},
	getters: {
		activeAccount(state) {
			let acc = state.accounts.find(item => item.id === state.activeAccountId);
			if(acc === undefined) {
				return {id: 0,
						psfeedinfo: {
							lastfetchtime: 0
						},
				};
			}
			else return acc;
		},
		accountById: (state) => (id) => {
			return state.accounts.find(item => item.id === id);
		},
	},
	mutations: {
		setRunRulesStatus(state, newstatus) {
			state.rulesrunning = newstatus;
		},
		addToRecentRecTx(state, mytx) {
			state.recentReconciledTransactions.push(mytx);
		},
		showKalert(state, responseObject) {
			state.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) state.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			if (responseObject.success === 1) responseObject.alerttype = 'success';
			state.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) state.kalert.type = responseObject.type;
			state.kalert.shown = true;
		},
		closeKalert(state) {
			state.kalert.shown = false;
		},
		updateAccountsLists(state, accarr) {
			state.accounts = accarr;
		},
		updatePayeesList(state, payeearr) {
			state.payees = payeearr;
		},
		updateSubcatsList(state, subcatarr) {
			state.subcats = subcatarr;
		},
		updateSecuritiesList(state, secarr) {
			state.securities = secarr;
		},
		updateWsjCount(state) {
			state.wsjcheckcount = state.wsjcheckcount + 1;
		},
		setSesscheckComplete(state) {
			state.sesscheckcomplete = true;
		},
		setActiveAccount(state, accid) {
			state.activeAccountId = accid;
		},
		unsetActiveAccount(state) {
			state.activeAccountId = 0;
		},
		setPocketsmithSyncStatus(state, newstatus) {
			state.pocketsmithsyncing = newstatus;
		},
		setWsjStockSyncStatus(state, newstatus) {
			state.wsjstocksyncing = newstatus;
		},
		setUser(state, uob) {
			state.user = uob;
		},
	},
	actions: {},
});

Vue.mixin({
	methods: {
		numdisplay: function(num) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
		},
		numdisplayround: function(num) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		pctdiff: function(fromnum, tonum, dpprecision) {
			if(fromnum === 0 || tonum === 0) return 0;
			let x = (tonum - fromnum) / fromnum * 100;
			return x.toFixed(dpprecision);
		},
		securityPriceByTicker(ticker) {
			let sec = this.$store.state.securities.find(item => item.ticker === ticker)	;
			if(sec !== undefined) {
				return sec.latestprice;
			}
			return false;
		},
		dateobToYmd(str) {
			var dd = String(str.getDate()).padStart(2, '0');
			var mm = String(str.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = str.getFullYear();
			return yyyy +'-'+ mm + '-' + dd;
		},
	},
});

new Vue({
	router,
	store: store,
	render: h => h(App),
}).$mount('#app')
